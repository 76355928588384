import React, { useEffect } from "react";
import inline from './InlineStyles.js';


export default (props) => {
      useEffect(()=>{
        document.title =" De bästa barnkläderna till förskolan. Köp rätt billiga kläder till ditt barn."
      })

      return (
        <>
          <h1 style={inline.h1}>
            Här samlar vi våra bästa tips för barnkläder till förskolan, där dagarna är fyllda med lek oavsett väder.
            Riktigt billiga barnkläder kan ibland fungera utmärkt, i andra fall krävs det barnkläder av hög kvalitet.
          </h1>
          <p style={inline.p}>
            Som förälder är det svårt att veta hur många plagg som behöver köpas av olika typer av kläder. Därför har vi satt ihop en lista med barnkläder som garanterat behövs på förskolan. Behovet av kläder varierar såklart efter säsong, sommar-, vår-, höst- och vinter- barnkläder har alla sin plats. Med rätt köp kan ofta höstkläderna användas även under vintern. Köp dina plagg enligt lager på lager metoden så kan du lätt justera efter varierande temperatur.
          </p>
          <p style={inline.p}>Vi ska försöka skriva utförliga recensioner, med praktiska tips, för och nackdelar med vissa produkter vi köpt och testat. Eller varför vi väljer en viss typ av produkt. Så att du förhoppningsvis kan undvika felköp och träffa rätt direkt. Vi har bland annat testat namnlappar på olika material och utsatt dem för vardagspåfrestningar. Många av sidorna innehåller sponsrade länkar men alla produkter som recenseras har vi betalat för.</p>
          </>
      )
}
