import React from "react"
import Layout from "../components/layout";
import Intro from "../components/intro";
import ProductCategories from "../components/productCategories";
import { Helmet } from 'react-helmet';


export default function Home() {
  return (
      <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1" />
      </Helmet>
      <Layout>
        <Intro></Intro>
        <ProductCategories>
        </ProductCategories>
      </Layout>
      </>
    )
}
